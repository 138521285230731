@import url("https://use.typekit.net/qrb3wez.css");
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&display=swap');

::selection {
    background: black;
    color: white;
  }
::-moz-selection {
    background: black;
    color: white;
}


body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
}

.bold {
    font-weight: 700;
}

.nav {
    width: 100vw;
    height: 100px;
    display: inline-flex;
}

.navBlock {
    width: 25vw;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.navBlock:hover {
    background-color: black;
    p {
        color:white;
        transform: rotate(-15deg);
    }
}

.navBlock p {
    color: black;
    font-size: 18px;
}



section {
    width: 50vw;
    height: calc(100vh - 100px);
    
}

main {
    display: inline-flex;
    width: 100vw;
}

.container {
    display: flex;
}

.sideCat {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-style: italic;
    z-index: 10;
}
.cat {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5d7d7;
    z-index: -101;
}

.cat p {
    transform: rotate(-90deg);
}

.workList {
    height: 100%;
    width: calc(50vw - 100px);
    display: grid;
}

.workItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: gitan-latin, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
    background-color: #f5d7d7;
}

.workItem {
    text-decoration: none;
}

.workItem:hover {
    background-color: black;
    p {
        color: white;
    }
}

.workItem p {
    color: black;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5d7d7;
}

.playList {
    width: 50vw;
    display: grid;
    height: calc(100vh - 100px);
}

.playList > a {
    background-color: #d4e8cc;
}

.aboutItem {
    height: 20vh;
}

.socialList {
    height: calc(60vh - 100px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.socialItem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    height: 100%;
    color: black;
    background-color: #cce2e8;
}

.socialItem p {
    font-family: gitan-latin, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 24px;
    color: black;
}

.aboutContent {
    position: relative !important;
    height: auto !important;
    display: inline !important;

}

.aboutContent p {
    width: 80vw !important;
    margin: auto;
    font-size: 18px;
}

.aboutContent div {
    width: 90vw !important;
    height: 300px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
}

.aboutContainer {
    display: block !important;
}

.aboutList {
    margin-top: 0px !important;
}

.aboutList > a {
    background-color: #cce2e8;
}

.contentContainer {
    padding: 5vw;
}

.contentContainer h1 {
    font-family: gitan-latin, sans-serif;
    font-weight: 500 !important;
    font-style: italic;
    font-size: 45px;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-align: center;
    width: 100%;
    @media screen and (max-width: 1300px) {
        font-size: 40px;
    }
    @media screen and (max-width: 1120px) {
        font-size: 35px;
    }
    @media screen and (max-width: 980px) {
        font-size: 32px;
    }
    @media screen and (max-width: 880px) {
        font-size: 45px;
    }
}





.contentContainer h2 {
    margin-top: 20px;
    font-family: gitan-latin, sans-serif;
    font-weight: 500 !important;
    font-style: normal;
    @media screen and (max-width: 1120px) {
        font-size: 20px;
    }
}

.memojiFace {
    width: 100%;
    height: 150px;
    background-image: url(../assets/img/memoji.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 30px;
    @media screen and (max-width: 800px) {
        width: 30%;
        height: 100px;
        margin-top: 20px;
        margin-bottom: 0;
    }
    @media screen and (max-width: 500px) {
        margin-top: 0px;
    }
    @media screen and (max-width: 350px) {
        height: 70px;
    }
}

.memojiFace:hover {
    background-image: url(../assets/img/memoji2.png);
    cursor: url(../assets/img/cursorHeart.png), pointer;
}

.noDisplay {
    display: none !important;
    background-color: none;
}

.hoverImage {
    width: 50vw;
    height: calc(100vh - 100px);
}

.socialItem:hover {
    p {
        color: white;
    }
}

.hoverPT {
    background-color: #f3ffe2;
    justify-content: center;
    align-items: center;
    display: flex;
}

.hoverPT > div {
    background-image: url(../assets/img/pt.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}

.hoverLW {
    background-color: #cfdced !important;
    justify-content: center;
    align-items: center;
    display: flex;
}

.hoverLW > div {
    background-image: url(../assets/img/lw.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}

.hoverFLUX {
    background-color: #b2beea !important;
}

.hoverFLUX > div {
    background-image: url(../assets/img/fluxFeb2.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}

.hoverCO {
    background-color: #F2D49C !important;
}

.hoverCO > div {
    background-image: url(../assets/img/coFeb2.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}


.hoverCATB {
    background-color: #eed4b5 !important;
}

.hoverCATB > div {
    background-image: url(../assets/img/catb.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}

.hoverBA19 {
    background-color: #e4ddeb !important;
}

.hoverBA19 > div {
    background-image: url(../assets/img/ba19.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}

.hoverCODE {
    background-color: #b3e2cb !important;
}

.hoverCODE > div {
    background-image: url(../assets/img/code.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}

.hoverDRUM {
    background-color: #f1cfc6 !important;
}

.hoverDRUM > div {
    background-image: url(../assets/img/drum.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90%;
    width: 90%;
    background-position: center;
    margin: auto;
}
.hoverEND {
    background-color: cyan;
}

#ig:hover{
    background-color: #da3a91;
}

#gh:hover{
    background-color: #2c3395;
}

#li:hover{
    background-color: #2a7ae7;
    border-top: solid black 2px;
}

#sp:hover{
    background-color: #19e33a;
    border-top: solid black 2px;
}


@media screen and (min-width: 2000px) {
    .contentContainer h1 {
        font-size: 60px;

    }
    
    .contentContainer h2 {
        margin-top: 20px;
        font-size: 40px;
    }

    .nav {
        height: 200px;
    }

    .navBlock p {
        font-size: 30px;
    }
    section {
        height: calc(100vh - 200px);
    }
    .sideCat {
        width: 200px;
    }
    .hoverImage {
        height: calc(100vh - 200px);
    }
    .playList {
        height: calc(100vh - 200px);
    }
    .workItem {
        font-size: 32px;
    }
    .cat p {
        font-size: 25px;
    } 
  }

#noItalicHand {
    font-style: normal;
}

.hello, .hello span {
    display: inline-block;
}

.hello2, .hello2 span {
    display: inline-block;
}

.mContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.mContainer h1{
    font-family: gitan-latin, sans-serif;
    font-weight: 500 !important;
    font-style: italic;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 0px;
    @media screen and (max-width: 400px) {
    font-size: 25px;
    }
    @media screen and (max-width: 320px) {
    font-size: 20px;
    }
}

.mContainer h2{
    font-family: gitan-latin, sans-serif;
    font-weight: 500 !important;
    font-style: normal;
    font-size: 17px;
    width: 70vw;
    margin-top: 10px;
    @media screen and (max-width: 400px) {
    font-size: 14px;
    }
    @media screen and (max-width: 320px) {
    font-size: 12px;
    }
}

.playEnd {
    background-color: #d4e8cc;
}

.playSection {
    background-color: #d4e8cc;
}

.aboutSection {
    background-color: #cce2e8;
}

.aboutSection > div {
    background-color: #cce2e8;
}

.workSection > div {
    background-color: #f5d7d7;
}

.playText2 {
    font-family: gitan-latin, sans-serif;
    font-weight: 500 !important;
    font-style: italic;
    font-size: 25px;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-align: center;
    width: 90%;
}

.aboutPic1 {
    background-image: url(../assets/img/about1update.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
}

.aboutPara {
    width: 100%;
    font-size: 18px;
    line-height: 1.5em;
}

.aboutPara a {
    text-decoration: underline;
    color: black;
}

.aboutPara a:hover {
    font-weight: 600;
}

.aboutPara a::selection {
    color: grey;
  }
.aboutPara a::-moz-selection {
    color: grey;
}

.aboutSectionRight {
    overflow: auto;
    align-items: flex-start;
}

.aboutPic2 {
    background-image: url(../assets/img/about2update.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
}

@media screen and (min-width: 2000px) {
    .aboutPara {
        width: 100%;
        font-size: 30px;
        line-height: 1.5em;
    }
    .aboutPic1 {
        height: 600px;
    }
    .aboutPic2 {
        height: 600px;
    }
}